import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, Container, createTheme, useMediaQuery } from '@mui/material';
import { usingFonts } from './constants/usingFonts';
import HomeLarge from './viewLarge/HomeLarge';
import HomeSmall from './viewSmall/HomeSmall';
import ProductsSmall from './viewSmall/ProductsSmall';
import ProductsLarge from './viewLarge/ProductsLarge';


function App() {
  const fonts = usingFonts();

  const mainTheme = createTheme({
    typography: {
      fontFamily: fonts,
    },
  });

  const isLargeScreen = useMediaQuery('(min-width:920px)');

  return (
    <ThemeProvider theme={mainTheme}>
      {/* <Router>
        <Container disableGutters={true} style={{ maxWidth: 1620 }}>
          <Routes>
            <Route path="/" element={isLargeScreen ? <HomeLarge /> : <HomeSmall />} />
            <Route path="products" element={isLargeScreen ? <ProductsLarge /> : <ProductsSmall />}/>
          </Routes>
        </Container>
      </Router> */}
    </ThemeProvider>
  );
}

export default App;